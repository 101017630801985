const { forEach, isEmpty } = require("lodash");

$(document).ready(function () {
    if ($(".slider_options").length > 0) {
        var values = [];
        values.push([1, 2, 3, 5, 10, 20, 40, 60]);
        values.push([3, 10, 50, 100, 200, 300, 500, 1000]);
        values.push([3, 5, 10, 30, 50, 100, 200, 600]);
        values.push([3, 5, 10, 25, 50, 100, 200, 600]);
        // values.push([30, 80, 150, 300, 500, 1000, 5000]);
        let components = "";
        for (let index = 1; index <= 4; index++) {
            $("#range_0" + index).ionRangeSlider({
                skin: "round",
                grid: true,
                from: 0,
                values: values[index - 1],
                // prefix:"$"
            });
        }

        for (let index = 1; index <= 4; index++) {
            $("#range_0" + (index + 4)).ionRangeSlider({
                skin: "round",
                grid: true,
                from: 0,
                values: values[index - 1],
                // prefix:"$"
            });
        }

        var cost = [];

        cost.push([0.00, 0.9, 1.35, 2.0, 2.9, 4.5, 6.3, 6.75]);
        cost.push([0.00, 2.9, 14.5, 26.1, 37.7, 43.5, 50.75, 72.5]);
        cost.push([0.00, 4.95, 9.9, 26.7, 32.2, 49.5, 69.3, 148.5]);
        cost.push([0.00, 4.25, 8.5, 19.1, 27.6, 42.5, 59.5, 127.5]);
        // cost.push([0.00,2.1, 5.6, 9.45, 13.65, 17.5, 24.5, 87.5]);

        cost.push([0.00, 9.2, 13.8, 20.7, 29.8, 45.9, 64.3, 68.85]);
        cost.push([0.00, 29.6, 147.9, 266.22, 384.5, 443.7, 517.65, 739.5]);
        cost.push([0.00, 47.5, 95.0, 256.6, 308.9, 475.2, 665.3, 1425.6]);
        cost.push([0.00, 40.8, 81.6, 183.6, 265.2, 408.0, 571.2, 1224.0]);
        // cost.push([0.00,21.4, 57.1, 96.4, 139.2, 178.5, 249.9, 892.5]);

        for (let index = 1; index <= 8; index++) {
            // if (index <= 4) {
            //     console.log("Slider " + index + " changed to: " + $("#range_0" + index).val());
            //     let key = parseInt(getKeyByValue(values[index - 1],parseInt($("#range_0" + index).val()))
            //     );
            //     let value = cost[index - 1][key];
            //     // $("#sliderCost" + index).text(" FREE");
            //     $("#sliderCost" + index).text(" " + value.toFixed(2));
            //     $("#sliderCostMonth" + index).text(" " + value.toFixed(2));
            //     $("#sliderValue" + index).text(" " + parseInt($("#range_0" + index).val()));
            //     $("#sliderValueMonth" + index).text(" " + parseInt($("#range_0" + index).val()));
            //     $("#index_" + index).val(key); 
            //     if (parseInt($("#range_0" + index).val()) > 0) {
            //         $("#add_ons").show();
            //         $("#add_ons_list").show(); 
            //     } else {
            //         $("#add_ons").hide();
            //         $("#add_ons_list").hide(); 
            //     }
            // } else {
            //     let key = parseInt(
            //         getKeyByValue( values[index - 5], parseInt($("#range_0" + index).val()))
            //     );
            //     let value = cost[index - 1][key];
            //     $("#sliderCost" + index).text(" " + value.toFixed(2));
            //     $("#sliderCostYear" + index).text(" " + value.toFixed(2));
            //     $("#sliderValue" + index).text(" " + parseInt($("#range_0" + index).val()));
            //     $("#sliderValueYear" + index).text( " " + parseInt($("#range_0" + index).val())
            //     );
            //     $("#index_" + index).val(key);

            //     if (parseInt($("#range_0" + index).val()) > 0) {
            //         $("#add_ons_year").show();
            //         $("#add_ons_list_year").show();
            //     } else {
            //         $("#add_ons_year").hide();
            //         $("#add_ons_list_year").hide();
            //     } 
            // }
            $("#range_0" + index).on("change", function () {
                if (index <= 4) {
                    console.log("Slider " + index + " changed to: " + $(this).val());
                    let key = parseInt(getKeyByValue(values[index - 1],parseInt($(this).val()))
                    );
                    let value = cost[index - 1][key];
                    // $("#sliderCost" + index).text(" FREE");
                    $("#sliderCost" + index).text(" " + value.toFixed(2));
                    $("#sliderCostMonth" + index).text(" " + value.toFixed(2));
                    $("#sliderValue" + index).text(" " + parseInt($(this).val()));
                    $("#sliderValueMonth" + index).text(" " + parseInt($(this).val()));
                    $("#index_" + index).val(key); 
                    if (parseInt($(this).val()) > 0) {
                        $("#add_ons").show();
                        $("#add_ons_list").show(); 
                    } else {
                        $("#add_ons").hide();
                        $("#add_ons_list").hide(); 
                    }
                } else {
                    let key = parseInt(
                        getKeyByValue( values[index - 5], parseInt($(this).val()))
                    );
                    let value = cost[index - 1][key];
                    $("#sliderCost" + index).text(" " + value.toFixed(2));
                    $("#sliderCostYear" + index).text(" " + value.toFixed(2));
                    $("#sliderValue" + index).text(" " + parseInt($(this).val()));
                    $("#sliderValueYear" + index).text( " " + parseInt($(this).val())
                    );
                    $("#index_" + index).val(key);

                    if (parseInt($(this).val()) > 0) {
                        $("#add_ons_year").show();
                        $("#add_ons_list_year").show();
                    } else {
                        $("#add_ons_year").hide();
                        $("#add_ons_list_year").hide();
                    } 
                }
                totalValueMonthly();
                totalValueYearly();
            });
        }

        // let switch_values = [];
        // switch_values.push([10.00,20.00,200.00]);

        // for (let index = 1; index <= 3; index++) {
        //     // const element = array[index];
        //     $("#switch" + index).on("click", function () {

        //         let switchValues = [];
        //         let switchCosts = [];
        //         if($(this).is(":checked")){

        //             if(index<=3){
        //                 var value = $(this).val()
        //                 $("#switchCost1_" + index).text("$ " + value).addClass("color-text");
        //                 $(".col-9_"+ index).addClass("color-text");

        //                 switchValues.push($(this).val());
        //                 let cost = parseFloat($(this).closest(".checkbox__body").find(".price").text().replace("$", ""));
        //                 switchCosts.push(cost);

        //             }else{
        //                 var value = $(this).val()
        //                 $("#switchCost2_" + index).text("$ " + value).addClass("color-text");
        //                 $(".col-9_"+ index).addClass("color-text");

        //             }

        //         }
        //         else{
        //             if(index<=3){
        //                 $("#switchCost1_" + index).text("$ " + 0).removeClass("color-text");
        //                 $(".col-9_"+ index).removeClass("color-text");

        //             }else{
        //                 $("#switchCost2_" + index).text("$ " + 0).removeClass("color-text");
        //                 $(".col-9_"+ index).removeClass("color-text");

        //             }

        //         }

        //         $("#switchValues").val(JSON.stringify(switchValues));
        //         $("#switchCosts").val(JSON.stringify(switchCosts));

        //         totalValueMonthly();
        //         totalValueYearly();
        //     });

        // }
    }

   

    if ($(".slider_update").length > 0) {
        $.ajax({
            type: "post",
            url: "/plan/update_plan/get_userplan",
            data: {
                _token: $('meta[name="csrf-token"]').attr("content"),
                user_id: $("#user_id").val(),
            },
            success: function (res) {
                if (res.status == true) {
                    // if ($(".slider_options").length > 0) {
                    res.user_plan.forEach((display) => {
                        display.plan_sub.forEach((plans) => {
                            if (display.category == "monthly") {
                                // console.log(display.category)
                                ipam_range.update({
                                    from: plans.IPAM[2],
                                });
                                health_range.update({
                                    from: plans.HealthCheck[2],
                                });
                                management_range.update({
                                    from: plans.ServerManagement[2],
                                });
                                monitor_range.update({
                                    from: plans.ServerMonitor[2],
                                });
                                inventory_range.update({
                                    from: plans.Inventory[2],
                                });
                                ipam_yearly_range.update({
                                    from: 4,
                                });
                                health_yearly_range.update({
                                    from: 4,
                                });
                                management_yearly_range.update({
                                    from: 4,
                                });
                                monitor_yearly_range.update({
                                    from: 4,
                                });
                                inventory_yearly_range.update({
                                    from: 4,
                                });
                            } else {
                                ipam_range.update({
                                    from: 4,
                                });
                                health_range.update({
                                    from: 4,
                                });
                                management_range.update({
                                    from: 4,
                                });
                                monitor_range.update({
                                    from: 4,
                                });
                                inventory_range.update({
                                    from: 4,
                                });
                                ipam_yearly_range.update({
                                    from: plans.IPAM[2],
                                });
                                health_yearly_range.update({
                                    from: plans.HealthCheck[2],
                                });
                                management_yearly_range.update({
                                    from: plans.ServerManagement[2],
                                });
                                monitor_yearly_range.update({
                                    from: plans.ServerMonitor[2],
                                });
                                inventory_yearly_range.update({
                                    from: plans.Inventory[2],
                                });
                            }
                        });
                    });
                    // }
                }
            },
        });
    }

    function totalValueMonthly() {
        let total = 0; 
        for (let index = 1; index <= 4; index++) {
            // $('input[id^= "range_0"]').each(function(i,e){
            let key = parseInt(
                getKeyByValue(
                    values[index - 1],
                    parseInt($('input[id="range_0' + index + '"]').val())
                )
            );
            let value = cost[index - 1][key];
            total += parseFloat(value);
            $("#sliderCost1_" + index) .text("$" + value.toFixed(2)).addClass("color-text");
            $(".colm-9_" + index).addClass("color-text");
            $("#cost_0" + index).val(value);
        }
        for (let index = 1; index <= 4; index++) {
            if ($("#switch" + index).is(":checked")) {
                var value = $("#switch" + index).val();
                total += parseFloat(value);
                // console.log(total)
                // $("#switchCost1_" + index).text("$ " + value).addClass("color-text");
                $("#switchCost1_" + index);
                // $(".col-9_"+ index).addClass("color-text");
                $(".col-9_" + index);
                $("#costSwitch_0" + index).val(value);
            } else {
                $("#switchCost1_" + index)
                    .text("$ 0")
                    .removeClass("color-text");
                $(".col-9_" + index).removeClass("color-text");
                $("#costSwitch_0" + index).val(0);
            }
        }
        // $("#totalValueMonthly").text(" " + total.toFixed(2));
        if (total === 0.00) {
            $("#totalValueMonthly").text("FREE");
        } else {
            $("#totalValueMonthly").text("$ " + total.toFixed(2));
        }
        $("#totalmonthly").val(total.toFixed(2));
        // console.log(total);

        // console.log("Total Monthly Value: " + total);
        return total.toFixed(2);
    }

    function totalValueYearly() {
        let totalYearly = 0;
        for (let index = 5; index <= 8; index++) {
            let key = parseInt(
                getKeyByValue(
                    values[index - 5],
                    parseInt($('input[id="range_0' + index + '"]').val())
                )
            );
            let value = cost[index - 1][key];
            totalYearly += parseFloat(value);
            // $("#sliderCost2_" + index).text("$" + value).addClass("color-text");
            // $("#sliderCost2_" + index).text("$" + (value !== undefined ? value : "0.00")).addClass("color-text");
            $("#sliderCost2_" + index).text("$" + (value !== undefined ? value.toFixed(2) : "0.00")).addClass("color-text"); 
            $(".colm-9_" + index).addClass("color-text");
            $("#cost1_0" + index).val(value);
        }

        // for (let index = 4; index <= 6; index++) {
        //     if ($("#switch" + index).is(":checked")) {
        //         var value = $("#switch" + index).val();
        //         totalYearly += parseFloat(value);
        //         $("#switchCost2_" + index)
        //             .text("$ " + value)
        //             .addClass("color-text");
        //         $(".col-9_" + index).addClass("color-text");
        //         $("#costSwitchYear_0" + index).val(value);
        //     } else {
        //         $("#switchCost2_" + index)
        //             .text("$ 0")
        //             .removeClass("color-text");
        //         $(".col-9_" + index).removeClass("color-text");
        //         $("#costSwitchYear_0" + index).val(0);
        //     }
        // }

        
        $("#totalValueYearly").text(
            " " + (isNaN(totalYearly) ? "FREE" : totalYearly.toFixed(2))
        );


        if (totalYearly === 0.00) {
            $("#totalValueYearly").text("FREE");
        } else {
            $("#totalValueYearly").text(" " + (isNaN(totalYearly) ? "FREE" : totalYearly.toFixed(2)));
        }
         
        // $("#totalYearly").val(totalYearly.toFixed(2));
        $("#totalYearly").val(
            isNaN(totalYearly) ? "0.00" : totalYearly.toFixed(2)
        );

        // return (totalYearly.toFixed(2));
        return isNaN(totalYearly) ? "0.00" : totalYearly.toFixed(2);
    }

    $(document).on("click", "#subscribe", function () {

        var sliderValues = [];
        var sliderCosts = []; 
        var selectedAddons = []; 

        selectedAddons = [];

        console.log(selectedAddons);

        var plan_price = totalValueMonthly() || 0;

        for (let index = 1; index <= 8; index++) {
            sliderValues.push(
                $("#sliderValue" + index)
                    .text()
                    .trim()
            );
            sliderCosts.push(
                $("#sliderCost" + index)
                    .text()
                    .trim()
            );
        }

        $(".switches:checked").each(function () {
            var switchName = $(this).attr("data-switch-name");
            var switchCost = $(this).val();
            var switchIndex = parseInt(
                $(this).attr("id").replace("switch", "")
            );

            if (switchIndex >= 1 && switchIndex <= 4) {
                selectedAddons.push(switchName + ":" + switchCost);
            }
        });

        $.ajax({
            type: "POST",
            url: "/pricing/create",
            data: {
                _token: $('meta[name="csrf-token"]').attr("content"),
                ipam: $('[name="ipam"]').val(),
                healthcheck: $('[name="healthcheck"]').val(),
                server_manage: $('[name="server_manage"]').val(),
                server_monitor: $('[name="server_monitor"]').val(),
              

                agent_id: $('[name="agent_id"]').val(),
  
                plan_category: "MonthlyPlan",
                sliderValues: sliderValues,
                sliderCosts: sliderCosts,

                selectedAddons: selectedAddons,
                plan_price: plan_price,
            },
            success: function (res) {
                if (res.message) {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                        padding: "2em",
                    }).then((res) => {
                        if (res.isConfirmed) {
                            window.location.reload();
                        }
                    });
                } else {
                    Swal.fire({
                        title: "An error occurred",
                        text: "Something went wrong while storing the purchase plan.",
                        icon: "error",
                        padding: "2em",
                    });
                }
            },
            error: function (xhr, status, error) {
                // Handle errors here
                console.log(error);
                Swal.fire({
                    title: "An error occurred",
                    text: "Something went wrong while sending the request.",
                    icon: "error",
                    padding: "2em",
                });
            },
        });
    });

    $(document).on("click", "#subscribeYearly", function () {
        // var plan_category = "Monthly Plan";
        var sliderValues = [];
        var sliderCosts = [];
        var selectedAddons = [];

        var plan_price = totalValueYearly() || 0;

        for (let index = 5; index <= 8; index++) {
            sliderValues.push(
                $("#sliderValue" + index)
                    .text()
                    .trim()
            );
            sliderCosts.push(
                $("#sliderCost" + index)
                    .text()
                    .trim()
            );
        }

        $(".switches:checked").each(function () {
            var switchName = $(this).attr("data-switch-name");
            var switchCost = $(this).val();
            var switchIndex = parseInt(
                $(this).attr("id").replace("switch", "")
            );

            if (switchIndex >= 5 && switchIndex <= 8) {
                selectedAddons.push(switchName + ":" + switchCost);
            }
        });

        $.ajax({
            type: "POST",
            url: "/pricing/create",
            data: {
                _token: $('meta[name="csrf-token"]').attr("content"),
                ipam: $('[name="ipam_year"]').val(),
                healthcheck: $('[name="healthcheck_year"]').val(),
                server_manage: $('[name="server_manage_year"]').val(),
                server_monitor: $('[name="server_monitor_year"]').val(),
                // Inventory: $('[name="Inventory_year"]').val(),

                agent_id: $('[name="agent_id_year"]').val(),

                plan_category: "YearlyPlan",
                sliderValues: sliderValues,
                sliderCosts: sliderCosts,
                selectedAddons: selectedAddons,
                plan_price: plan_price,
            },
            success: function (res) {
                if (res.message) {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                        padding: "2em",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                } else {
                    Swal.fire({
                        title: "An error occurred",
                        text: "Something went wrong while storing the purchase plan.",
                        icon: "error",
                        padding: "2em",
                    });
                }
            },

            error: function (xhr, status, error) {
                // Handle errors here
                console.log(error);
                Swal.fire({
                    title: "An error occurred",
                    text: "Something went wrong while sending the request.",
                    icon: "error",
                    padding: "2em",
                });
            },
        });
    });

    $(document).on("click", "#temp_data", function () {

        var sliderValues = [];
        var sliderCosts = []; 
        var selectedAddons = [];

        selectedAddons = [];

        // console.log(selectedAddons);

        var plan_price = totalValueMonthly() || 0;
 

        for (let index = 1; index <= 8; index++) {
            sliderValues.push(
                $("#sliderValue" + index)
                    .text()
                    .trim()
            );
            sliderCosts.push(
                $("#sliderCost" + index)
                    .text()
                    .trim()
            );
        }

        $(".switches:checked").each(function () {
            var switchName = $(this).attr("data-switch-name");
            var switchCost = $(this).val();
            var switchIndex = parseInt(
                $(this).attr("id").replace("switch", "")
            );

            if (switchIndex >= 1 && switchIndex <= 4) {
                selectedAddons.push(switchName + ":" + switchCost);
            }
        });

        $.ajax({
            type: "POST",
            url: "/pricing/process/slider",
            data: {
                _token: $('meta[name="csrf-token"]').attr("content"),
                ipam: $('[name="ipam"]').val(),
                healthcheck: $('[name="healthcheck"]').val(),
                server_manage: $('[name="server_manage"]').val(),
                server_monitor: $('[name="server_monitor"]').val(),
                // Inventory: $('[name="Inventory"]').val(),

                plan_category: "Monthly Plan",
                sliderValues: sliderValues,
                sliderCosts: sliderCosts,

                selectedAddons: selectedAddons,
                plan_price: plan_price,
                agent_id: $('[name="agent_id"]').val(),

            },
            success: function (res) {
                if (res.message) {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                        padding: "2em",
                    }).then(() => {
                       
                        window.location.href = '/login';
                    });
                } else {
                    Swal.fire({
                        title: "An error occurred",
                        text: "Something went wrong while storing the purchase plan.",
                        icon: "error",
                        padding: "2em",
                    });
                }
            },
            error: function (xhr, status, error) {
                // Handle errors here
                console.log(error);
                Swal.fire({
                    title: "An error occurred",
                    text: "Something went wrong while sending the request.",
                    icon: "error",
                    padding: "2em",
                });
            },
        });
    });

    $(document).on("click", "#temp_data_year", function () {

        // var plan_category = "Monthly Plan";
        var sliderValues = [];
        var sliderCosts = [];
        var selectedAddons = [];

        var plan_price = totalValueYearly() || 0;

        for (let index = 5; index <= 8; index++) {
            sliderValues.push(
                $("#sliderValue" + index)
                    .text()
                    .trim()
            );
            sliderCosts.push(
                $("#sliderCost" + index)
                    .text()
                    .trim()
            );
        }

        $(".switches:checked").each(function () {
            var switchName = $(this).attr("data-switch-name");
            var switchCost = $(this).val();
            var switchIndex = parseInt(
                $(this).attr("id").replace("switch", "")
            );

            if (switchIndex >= 5 && switchIndex <= 8) {
                selectedAddons.push(switchName + ":" + switchCost);
                console.log(switchName + ":" + switchCost)
            }
        });

        $.ajax({
            type: "POST",
            url: "/pricing/process/slider",
            data: {
                _token: $('meta[name="csrf-token"]').attr("content"),
                ipam: $('[name="ipam_year"]').val(),
                healthcheck: $('[name="healthcheck_year"]').val(),
                server_manage: $('[name="server_manage_year"]').val(),
                server_monitor: $('[name="server_monitor_year"]').val(),
                // Inventory: $('[name="Inventory_year"]').val(),

                plan_category: "Yearly Plan",
                sliderValues: sliderValues,
                sliderCosts: sliderCosts,
                selectedAddons: selectedAddons,
                plan_price: plan_price,
                // agent_id: $("#agent_referral_year").val(),
                agent_id: $('[name="agent_id_year"]').val(),

                
            },
            success: function (res) {
                if (res.message) {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                        padding: "2em",
                    }).then(() => {
                       
                        window.location.href = '/login';
                    });
                } else {
                    Swal.fire({
                        title: "An error occurred",
                        text: "Something went wrong while storing the purchase plan.",
                        icon: "error",
                        padding: "2em",
                    });
                }
            },

            error: function (xhr, status, error) {
                // Handle errors here
                console.log(error);
                Swal.fire({
                    title: "An error occurred",
                    text: "Something went wrong while sending the request.",
                    icon: "error",
                    padding: "2em",
                });
            },
        });
    });

    $(document).on("click", ".update_subscribeMonthly", function () {
        if ($("#plan_category").val() == "YearlyPlan") {
            Swal.fire({
                showCancelButton: true,
                cancelButtonColor: "#3B71CA",
                cancelButtonText: "Cancel",
                confirmButtonColor: "#198754",
                confirmButtonText: "Yes",
                title: "You are about to change your plan to monthly subscription",
                text: "Do you want to continue?",
                icon: "question",
                padding: "2em",
            }).then((result) => {
                if (result.isConfirmed) {
                    var purchasePlanId = $(this).data("created_by");

                    var sliderValuesYear = [];
                    var sliderCostsYear = [];
                    var selectedAddonsYear = [];

                    var plan_priceYear = totalValueMonthly() || 0;

                    for (let index = 1; index <= 4; index++) {
                        sliderValuesYear.push(
                            $("#sliderValueMonth" + index)
                                .text()
                                .trim()
                        );
                        sliderCostsYear.push(
                            $("#sliderCostMonth" + index)
                                .text()
                                .trim()
                        );
                    }

                    $(".switchesMonth").each(function () {
                        var switchNameYear = $(this).attr("data-switch-name");
                        var switchCostYear = $(this).is(":checked")
                            ? $(this).val()
                            : "0.00";

                        if (
                            switchNameYear === "Switch1" ||
                            switchNameYear === "Switch2" ||
                            switchNameYear === "Switch3" ||
                            switchNameYear === "Switch4"
                        ) {
                            switchCostYear = "0.00";
                        }

                        selectedAddonsYear.push(
                            switchNameYear + ":" + switchCostYear
                        );
                    });

                    $.ajax({
                        type: "POST",
                        url: "/plan/purchase/update/" + purchasePlanId,
                        data: {
                            _token: $('meta[name="csrf-token"]').attr(
                                "content"
                            ),
                            id: purchasePlanId,
                            ipamYear: $('[name="ipamMonth"]').val(),
                            healthcheckYear: $(
                                '[name="healthcheckMonth"]'
                            ).val(),
                            server_manageYear: $(
                                '[name="server_manageMonth"]'
                            ).val(),
                            server_monitorYear: $(
                                '[name="server_monitorMonth"]'
                            ).val(),
                            
                            // InventoryYear: $('[name="InventoryMonth"]').val(),

                            plan_categoryYear: "MonthlyPlan",
                            sliderValuesYear: sliderValuesYear,
                            sliderCostsYear: sliderCostsYear,
                            selectedAddons: selectedAddonsYear,
                            plan_priceYear: plan_priceYear,

                            agent_id: $('[name="agent_idMonthly"]').val(),
                        },
                        success: function (res) {
                            if (res.message) {
                                if (res.success == true) {
                                    Swal.fire({
                                        title: res.message,
                                        icon: "success",
                                        padding: "2em",
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            window.location.href =
                                                "/plan/update_plan/" +
                                                purchasePlanId;
                                        }
                                    });
                                } else {
                                    Swal.fire({
                                        title: res.message,
                                        icon: "error",
                                        padding: "2em",
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            window.location.href = "/dashboard";
                                        }
                                    });
                                }
                            } else {
                                Swal.fire({
                                    title: "An error occurred",
                                    text: "Something went wrong while storing the purchase plan.",
                                    icon: "error",
                                    padding: "2em",
                                });
                            }
                        },

                        error: function (xhr, status, error) {
                            // Handle errors here
                            console.log(error);
                            Swal.fire({
                                title: "An error occurred",
                                text: "Something went wrong while sending the request.",
                                icon: "error",
                                padding: "2em",
                            });
                        },
                    });
                }
            });
        } else {
            var purchasePlanId = $(this).data("created_by");
            var sliderValuesMonth = [];
            var sliderCostsMonth = [];
            var selectedAddonsMonth = [];

            var plan_priceMonth = totalValueMonthly() || 0;

            for (let index = 1; index <= 4; index++) {
                sliderValuesMonth.push(
                    $("#sliderValueMonth" + index)
                        .text()
                        .trim()
                );
                sliderCostsMonth.push(
                    $("#sliderCostMonth" + index)
                        .text()
                        .trim()
                );
            }

            $(".switchesMonth").each(function () {
                var switchNameMonth = $(this).attr("data-switch-name");
                var switchCostMonth = $(this).is(":checked")
                    ? $(this).val()
                    : "0.00";

                if (
                    switchNameMonth === "Switch1" ||
                    switchNameMonth === "Switch2" ||
                    switchNameMonth === "Switch3" ||
                    switchNameMonth ===  "Switch4"
                ) {
                    switchCostMonth = "0.00";
                }

                selectedAddonsMonth.push(
                    switchNameMonth + ":" + switchCostMonth
                );
            });

            // $(".switches:checked").each(function() {
            //     var switchNameMonth = $(this).attr("data-switch-name");
            //     var switchCostMonth = $(this).val();
            //     selectedAddonsMonth.push(switchNameMonth + ":" + switchCostMonth);

            // });

            $.ajax({
                type: "POST",
                url: "/plan/purchase/update/" + purchasePlanId,
                data: {
                    _token: $('meta[name="csrf-token"]').attr("content"),
                    id: purchasePlanId,
                    ipamMonth: $('[name="ipamMonth"]').val(),
                    healthcheckMonth: $('[name="healthcheckMonth"]').val(),
                    server_manageMonth: $('[name="server_manageMonth"]').val(),
                    server_monitorMonth: $(
                        '[name="server_monitorMonth"]'
                    ).val(),
                    // InventoryMonth: $('[name="InventoryMonth"]').val(),

                    plan_categoryMonth: "MonthlyPlan",
                    // plan_category: "YearlyPlan",
                    sliderValuesMonth: sliderValuesMonth,
                    sliderCostsMonth: sliderCostsMonth,
                    selectedAddons: selectedAddonsMonth,
                    plan_priceMonth: plan_priceMonth,
                    agent_id: $('[name="agent_idMonthly"]').val(),
                },
                success: function (res) {
                    if (res.message) {
                        if (res.success == true) {
                            Swal.fire({
                                title: res.message,
                                icon: "success",
                                padding: "2em",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.href =
                                        "/plan/update_plan/" + purchasePlanId;
                                }
                            });
                        } else {
                            Swal.fire({
                                title: res.message,
                                icon: "error",
                                padding: "2em",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.href = "/dashboard";
                                }
                            });
                        }
                    } else {
                        Swal.fire({
                            title: "An error occurred",
                            text: "Something went wrong while storing the purchase plan.",
                            icon: "error",
                            padding: "2em",
                        });
                    }
                },

                error: function (xhr, status, error) {
                    // Handle errors here
                    console.log(error);
                    Swal.fire({
                        title: "An error occurred",
                        text: "Something went wrong while sending the request.",
                        icon: "error",
                        padding: "2em",
                    });
                },
            });
        }
    });

    $(document).on("click", ".update_subscribeYearly", function () {
        if ($("#plan_category").val() == "MonthlyPlan") {
            Swal.fire({
                showCancelButton: true,
                cancelButtonColor: "#3B71CA",
                cancelButtonText: "Cancel",
                confirmButtonColor: "#198754",
                confirmButtonText: "Yes",
                title: "You are about to change your plan to yearly subscription",
                text: "Do you want to continue?",
                icon: "question",
                padding: "2em",
            }).then((result) => {
                if (result.isConfirmed) {
                    var purchasePlanId = $(this).data("created_by");
                    var sliderValuesMonth = [];
                    var sliderCostsMonth = [];
                    var selectedAddonsMonth = [];

                    var plan_priceMonth = totalValueYearly() || 0;

                    for (let index = 5; index <= 8; index++) {
                        sliderValuesMonth.push(
                            $("#sliderValueYear" + index)
                                .text()
                                .trim()
                        );
                        sliderCostsMonth.push(
                            $("#sliderCostYear" + index)
                                .text()
                                .trim()
                        );
                    }

                    $(".switchesYear").each(function () {
                        var switchNameMonth = $(this).attr("data-switch-name");
                        var switchCostMonth = $(this).is(":checked")
                            ? $(this).val()
                            : "0.00";

                        if (
                            switchNameMonth === "Switch5" ||
                            switchNameMonth === "Switch6" ||
                            switchNameMonth === "Switch7" ||
                            switchNameMonth === "Switch8" 
                        ) {
                            switchCostMonth = "0.00";
                        }

                        selectedAddonsMonth.push(
                            switchNameMonth + ":" + switchCostMonth
                        );
                    });

                    $.ajax({
                        type: "POST",
                        url: "/plan/purchase/update/" + purchasePlanId,
                        data: {
                            _token: $('meta[name="csrf-token"]').attr(
                                "content"
                            ),
                            id: purchasePlanId,
                            ipamMonth: $('[name="ipamYear"]').val(),
                            healthcheckMonth: $(
                                '[name="healthcheckYear"]'
                            ).val(),
                            server_manageMonth: $(
                                '[name="server_manageYear"]'
                            ).val(),
                            server_monitorMonth: $(
                                '[name="server_monitorYear"]'
                            ).val(),
                            // InventoryMonth: $('[name="InventoryYear"]').val(),
                            

                            plan_categoryMonth: "YearlyPlan",
                            // plan_category: "YearlyPlan",
                            sliderValuesMonth: sliderValuesMonth,
                            sliderCostsMonth: sliderCostsMonth,
                            selectedAddons: selectedAddonsMonth,
                            plan_priceMonth: plan_priceMonth,

                            agent_id: $('[name="agent_idYearly"]').val(),
                        },
                        success: function (res) {
                            if (res.message) {
                                if (res.success == true) {
                                    Swal.fire({
                                        title: res.message,
                                        icon: "success",
                                        padding: "2em",
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            window.location.href =
                                                "/plan/update_plan/" +
                                                purchasePlanId;
                                        }
                                    });
                                } else {
                                    Swal.fire({
                                        title: res.message,
                                        icon: "error",
                                        padding: "2em",
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            window.location.href = "/dashboard";
                                        }
                                    });
                                }
                            } else {
                                Swal.fire({
                                    title: "An error occurred",
                                    text: "Something went wrong while storing the purchase plan.",
                                    icon: "error",
                                    padding: "2em",
                                });
                            }
                        },

                        error: function (xhr, status, error) {
                            // Handle errors here
                            console.log(error);
                            Swal.fire({
                                title: "An error occurred",
                                text: "Something went wrong while sending the request.",
                                icon: "error",
                                padding: "2em",
                            });
                        },
                    });
                }
            });
        } else {
            var purchasePlanId = $(this).data("created_by");

            var sliderValuesYear = [];
            var sliderCostsYear = [];
            var selectedAddonsYear = [];

            var plan_priceYear = totalValueYearly() || 0;
            for (let index = 5; index <= 8; index++) {
                sliderValuesYear.push(
                    $("#sliderValueYear" + index)
                        .text()
                        .trim()
                );
                sliderCostsYear.push(
                    $("#sliderCostYear" + index)
                        .text()
                        .trim()
                );
            }

            $(".switchesYear").each(function () {
                var switchNameYear = $(this).attr("data-switch-name");
                var switchCostYear = $(this).is(":checked")
                    ? $(this).val()
                    : "0.00";

                if (
                    switchNameYear === "Switch5" ||
                    switchNameYear === "Switch6" ||
                    switchNameYear === "Switch7" ||
                    switchNameYear === "Switch8"
                ) {
                    switchCostYear = "0.00";
                }

                selectedAddonsYear.push(switchNameYear + ":" + switchCostYear);
            });

            $.ajax({
                type: "POST",
                url: "/plan/purchase/update/" + purchasePlanId,
                data: {
                    _token: $('meta[name="csrf-token"]').attr("content"),
                    id: purchasePlanId,
                    ipamYear: $('[name="ipamYear"]').val(),
                    healthcheckYear: $('[name="healthcheckYear"]').val(),
                    server_manageYear: $('[name="server_manageYear"]').val(),
                    server_monitorYear: $('[name="server_monitorYear"]').val(),
                    // InventoryYear: $('[name="InventoryYear"]').val(),

                    plan_categoryYear: "YearlyPlan",
                    sliderValuesYear: sliderValuesYear,
                    sliderCostsYear: sliderCostsYear,
                    selectedAddons: selectedAddonsYear,
                    plan_priceYear: plan_priceYear,

                    agent_id: $('[name="agent_idYearly"]').val(),
                },
                success: function (res) {
                    if (res.message) {
                        if (res.status == true) {
                            Swal.fire({
                                title: res.message,
                                icon: "success",
                                padding: "2em",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.href =
                                        "/plan/update_plan/" + purchasePlanId;
                                }
                            });
                        } else {
                            Swal.fire({
                                title: res.message,
                                icon: "success",
                                padding: "2em",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.href = "/dashboard";
                                }
                            });
                        }
                    } else {
                        Swal.fire({
                            title: "An error occurred",
                            text: "Something went wrong while storing the purchase plan.",
                            icon: "error",
                            padding: "2em",
                        });
                    }
                },

                error: function (xhr, status, error) {
                    // Handle errors here
                    console.log(error);
                    Swal.fire({
                        title: "An error occurred",
                        text: "Something went wrong while sending the request.",
                        icon: "error",
                        padding: "2em",
                    });
                },
            });
        }
    });

    $(document).on("click", "#cancel_subs", function () {
        var cancelSubs = $(this).data("id");

        Swal.fire({
            showCancelButton: true,
            cancelButtonColor: "#3B71CA",
            cancelButtonText: "Cancel",
            confirmButtonColor: "#198754",
            confirmButtonText: "Yes, Cancel My Subscription",
            title: "Cancel Subscription",
            html: "Are you sure you want to cancel your subscription?<hr><br>You will not be billed after your subscription end date.",
            icon: "question",
            padding: "2em",
        }).then((result) => {
            if (result.value == true) {
                let loading = Swal.fire({
                    html: "Please Wait...",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                $.ajax({
                    type: "POST",
                    url: "/plan/purchase/delete",
                    data: {
                        _token: $('meta[name="csrf-token"]').attr("content"),
                        id: cancelSubs,
                    },
                    success: function () {
                        Swal.fire({
                            title: "Success!",
                            text: "Your Subscription Successfully Canceled",
                            icon: "success",
                            padding: "2em",
                        });
                        window.location.reload();
                    },
                    error: function () {
                        Swal.fire({
                            title: "Error!",
                            text: "Something went wrong while canceling the subscription.",
                            icon: "error",
                            padding: "2em",
                        });
                    },
                    complete: function () {
                        loading.close();
                    },
                });
            }
        });
    });

    var agent_purchase_table = $("#agent_purchase_table").DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        orderCellsTop: true,
        searchDelay: 500,
        lengthChange: false,
        // dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',
        buttons: {
            buttons: [
                {
                    extend: "excel",
                    className: "btn",
                },
                {
                    extend: "print",
                    className: "btn",
                },
            ],
        },

        ajax: {
            type: "POST",
            url: "/plan/subscription/agent_user_list/ajax",
            data: function (d) {
                d._token = $('meta[name="csrf-token"]').attr("content");
                d.agent_id = $('.agent_id').data('id');
            },
        },
        columns: [
            {
                data: "DT_RowIndex",
                name: "DT_RowIndex",
                className: "text-center align-middle",

                orderable: false,
                searchable: false,
            },
            {
                data: "created_by",
                name: "created_by",
                className: "text-center align-middle",
            },
            {
                data: "email",
                name: "email",
                className: "text-center align-middle",
            },
            {
                data: "created_at",
                name: "created_at",
                className: "text-center align-middle",
            },
            {
                data: "created_at_end",
                name: "created_at_end",
                className: "text-center align-middle",
            },
            {
                data: "plan_category",
                name: "plan_category",
                className: "text-center align-middle",
            },
            {
                data: "payment_status",
                name: "payment_status",
                className: "text-center align-middle",
                render: function (data, type, full, meta) {
                    if (data == 1) {
                        return '<span class="badge badge-success">Paid</span>';
                    } else {
                        return '<span class="badge badge-danger">Unpaid</span>';
                    }
                },
            },
            {
                data: "user_details",
                orderable: false,
                className: "text-center align-middle",
                render: function (data, type, row, meta) {
                    // return
                    //  '<button type="button" id="viewBill" data-id="' + data + '" class="btn btn-sm btn-primary px-3 viewBill m-1" data-toggle="tooltip" value="'+type_bill+'" data-placement="bottom" title="Lihat"><i class="fas fa-eye"></i></button>'+
                    return (
                        '<a href="/plan/subscription/detail/' +
                        data +
                        '"data-id="' +
                        data +
                        '" data-toggle="tooltip"  data-placement="bottom" title="Subscription Details" style="color: blue; text-decoration: underline;">Subscription Details</a>'
                    );
                },
            },
        ],

        order: [[0, "desc"]],

        oLanguage: {
            oPaginate: {
                sPrevious:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
                sNext: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
            },
            sInfo: "Paparan _PAGE_ daripada _PAGES_",
            sSearch:
                '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
            sSearchPlaceholder: "Search...",
            sLengthMenu: "List :  _MENU_",
        },
        stripeClasses: [],
        lengthMenu: [7, 10, 20, 50],
        pageLength: 10,
    });

    var purchase_table = $("#purchase_table").DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        orderCellsTop: true,
        searchDelay: 500,
        lengthChange: false,
        // dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',
        buttons: {
            buttons: [
                {
                    extend: "excel",
                    className: "btn",
                },
                {
                    extend: "print",
                    className: "btn",
                },
            ],
        },

        ajax: {
            type: "POST",
            url: "/plan/subscription/list/ajax",
            data: function (d) {
                d._token = $('meta[name="csrf-token"]').attr("content");
            },
        },
        columns: [
            {
                data: "DT_RowIndex",
                name: "DT_RowIndex",
                className: "text-center align-middle",

                orderable: false,
                searchable: false,
            },
            {
                data: "created_by",
                name: "created_by",
                className: "text-center align-middle",
            },
            {
                data: "email",
                name: "email",
                className: "text-center align-middle",
            },
            {
                data: "created_at",
                name: "created_at",
                className: "text-center align-middle",
            },
            {
                data: "created_at_end",
                name: "created_at_end",
                className: "text-center align-middle",
            },
            {
                data: "plan_category",
                name: "plan_category",
                className: "text-center align-middle",
            },
            {
                data: "payment_status",
                name: "payment_status",
                className: "text-center align-middle",
                render: function (data, type, full, meta) {
                    if (data == 1) {
                        return '<span class="badge badge-success">Paid</span>';
                    } else {
                        return '<span class="badge badge-danger">Unpaid</span>';
                    }
                },
            },
            {
                data: "user_details",
                orderable: false,
                className: "text-center align-middle",
                render: function (data, type, row, meta) {
                    // return
                    //  '<button type="button" id="viewBill" data-id="' + data + '" class="btn btn-sm btn-primary px-3 viewBill m-1" data-toggle="tooltip" value="'+type_bill+'" data-placement="bottom" title="Lihat"><i class="fas fa-eye"></i></button>'+
                    return (
                        '<a href="/plan/subscription/detail/' +
                        data +
                        '"data-id="' +
                        data +
                        '" data-toggle="tooltip"  data-placement="bottom" title="Subscription Details" style="color: blue; text-decoration: underline;">Subscription Details</a>'
                    );
                },
            },
        ],

        order: [[0, "desc"]],

        oLanguage: {
            oPaginate: {
                sPrevious:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
                sNext: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
            },
            sInfo: "Paparan _PAGE_ daripada _PAGES_",
            sSearch:
                '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
            sSearchPlaceholder: "Search...",
            sLengthMenu: "List :  _MENU_",
        },
        stripeClasses: [],
        lengthMenu: [7, 10, 20, 50],
        pageLength: 10,
    });

    $(document).on("change", "#department", function () {
        if ($("#department").val() == 0) {
            $("#priority").html(
                '<option value="low" selected>Low</option>' +
                    '<option value="med" >Medium</option>' +
                    '<option value="high" >High</option>' +
                    '<option value="urg" >Urgent</option>'
            );
        } else {
            $("#priority").html(
                '<option value="" selected>Select One</option>' +
                    '<option value="low" >Low</option>' +
                    '<option value="med" >Medium</option>' +
                    '<option value="high" >High</option>' +
                    '<option value="urg" >Urgent</option>'
            );
        }
    });

    var counter = 2;
    $(document).on("click", "#addInput", function () {
        let html =
            `<div class="col-12 content_area counter_` +
            counter +
            `"  data-counter="` +
            counter +
            `">
                        <div class="card">
                            <div class="card-header text-primary d-flex justify-content-between">
                                <span>Paragraph Section</span>
                                <button class="btn btn-danger minusInput" data-counter="` +
            counter +
            `" type="button"><i class="fas fa-trash-alt"></i></button>
                            </div>

                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="title` +
            counter +
            `">Subject<span class="text-danger">*</span></label>
                                            <textarea id="title` +
            counter +
            `" type="text" name="title[]" class="form-control title" placeholder="Enter your Subject Header"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="conten_blog` +
            counter +
            `">Content<span class="text-danger">*</span></label>
                                            <textarea id="conten_blog` +
            counter +
            `" type="text" class="form-control paragraph_content" placeholder="Enter you Content" style="height: 300px"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                    <label for="conten_blog_image` +counter +
                                    `">Content Image <span class="text-danger">*</span></label>
                                        <div class="form-group container" style="width:500px">
                                                <input type="file" class="dropify paragraph_image" id="conten_blog_image` +
            counter +
            `" accept="image/*" height="100">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>`;

        $(".blog_append_here").append(html);
        $("#conten_blog_image" + counter).dropify();
        counter++;
    });

    $(document).on("click", ".minusInput", function () {
        $(".counter_" + $(this).data("counter")).remove();
    });

    $(".dropify").dropify();

    $(document).on("click", "#post", function () {

        var formData = new FormData();
        formData.append("_token", $('meta[name="csrf-token"]').attr("content"));
        formData.append("title_blog", $("#title_blog").val());
        formData.append("blog_desc", $("#blog_desc").val());
        formData.append("cover_image", $("#cover_image")[0].files[0]);

        $(".content_area").each(function () {
            let counter = $(this).data("counter");
            formData.append("title[]", $(this).find(".title").val());
            formData.append(
                "content[]",
                $(this).find(".paragraph_content").val()
            );
            formData.append(
                "content_image[]",
                $(this).find(".paragraph_image")[0].files[0]
            );
        });

        $.ajax({
            type: "post",
            url: "/blog/blog_post",
            data: formData,
            processData: false,
            contentType: false,
            success: function (result) {
                var id = result.id;
                if (result.status == true) {
                    Swal.fire({
                        showCancelButton: true,
                        cancelButtonColor: "#3B71CA",
                        cancelButtonText: "Okay",
                        confirmButtonColor: "#198754",
                        confirmButtonText: "See Post",
                        title: result.msg,
                        icon: "success",
                        padding: "2em",
                    }).then((value) => {
                        if (value.isConfirmed) {
                            window.location.href =
                                "/blog/auth-blog-detail/" + id;
                        } else {
                            window.location.reload();
                        }
                    });
                } else {
                    Swal.fire({
                        title: result.msg,
                        icon: "error",
                        padding: "2em",
                    });
                }
            },
        });
    });

    $(document).on('click','#edit_blog',function(){
        var formData = new FormData();

        // Append the CSRF token to the FormData
        formData.append("_token", $('meta[name="csrf-token"]').attr("content"));

        // Append blog_id, title_blog, and blog_desc to the FormData
        formData.append("blog_id", $("#blog_id").val());
        formData.append("title_blog", $("#title_blog").val());
        formData.append("blog_desc", $("#blog_desc").val());

        // Append the cover image to the FormData
        var coverImage = $("#cover_image")[0].files[0];
        if (coverImage) {
            formData.append("cover_image", coverImage);
        }

        // Loop through content areas and append their data to the FormData
        $(".content_area").each(function (index) {
            console.log(index)
            var title = $(this).find(".title").val();
            var content = $(this).find(".paragraph_content").val();

            // Append title and content to the FormData
            formData.append("title[]", title);
            formData.append("content[]", content);

            // Append content image to the FormData
            var contentImage = $(this).find(".paragraph_image")[0].files[0];
            if (contentImage) {
                // formData.append("old_content_image[]", null);
                formData.append("content_image[]", contentImage);
            
            } else{
                // formData.append("content_image[]", $(this).find("#old_content_image").val());
                // formData.append("content_image[]", null);
                Swal.fire({
                    title: "Please insert an image into paragraph section",
                    icon: "error",
                    padding: "2em",
                });
                

            }
            // else{
            //     // formData.append("old_content_image[]", null);
            //     formData.append("content_image[]", null);
            // }
        });

        $.ajax({
            type:'post',
            url:'/blog/ajaxedit',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result){
                var id = result.id;
                if(result.status == true){
                    Swal.fire({
                       showCancelButton: true,
                       cancelButtonColor: '#3B71CA',
                       cancelButtonText: 'Okay',
                       confirmButtonColor: '#198754',
                       confirmButtonText: 'See Post',
                       title: result.msg,
                       icon: 'success',
                       padding: '2em'
                   }).then((value)=>{
                       if (value.isConfirmed) {
                        window.location.href = "/blog/auth-blog-detail/"+id

                      }
                      else{
                        window.location.reload()
                      }
                   })
                }else{
                    Swal.fire({
                       title: result.msg,
                       icon: 'error',
                       padding: '2em'
                   })
               }
            }
        })

        // var formData = new FormData();
        // formData.append("_token", $('meta[name="csrf-token"]').attr("content"));
        // formData.append("blog_id", $("#blog_id").val());
        // formData.append("title_blog", $("#title_blog").val());
        // formData.append("blog_desc", $("#blog_desc").val());

        // if($("#cover_image")[0].files[0]){
        //     formData.append("cover_image", $("#cover_image")[0].files[0]);
        // }

        // $(".content_area").each(function () {
        //     let counter = $(this).data("counter");
        //     formData.append("title[]", $(this).find(".title").val());
        //     formData.append(
        //         "content[]",
        //         $(this).find(".paragraph_content").val()
        //     );

        //     if($(this).find(".paragraph_image")[0].files[0]){

        //         formData.append(
        //             "content_image[]",
        //             $(this).find(".paragraph_image")[0].files[0]
        //         );

        //     }
        //     // else{

        //     //     Swal.fire({
        //     //         title: "Please insert an image in all pararaph  content",
        //     //         padding: "2em",
        //     //     });
        //     // }
        // });

        // $.ajax({
        //     type: "post",
        //     url: "/blog/ajaxedit",
        //     data: formData,
        //     processData: false,
        //     contentType: false,
        //     success: function (result) {
        //         var id = result.id;
        //         if (result.status == true) {
        //             Swal.fire({
        //                 showCancelButton: true,
        //                 cancelButtonColor: "#3B71CA",
        //                 cancelButtonText: "Okay",
        //                 confirmButtonColor: "#198754",
        //                 confirmButtonText: "See Post",
        //                 title: result.msg,
        //                 icon: "success",
        //                 padding: "2em",
        //             }).then((value) => {
        //                 if (value.isConfirmed) {
        //                     window.location.href =
        //                         "/blog/auth-blog-detail/" + id;
        //                 } else {
        //                     window.location.reload();
        //                 }
        //             });
        //         } else {
        //             Swal.fire({
        //                 title: result.msg,
        //                 icon: "error",
        //                 padding: "2em",
        //             });
        //         }
        //     },
        // });
    })

    $(document).on("click", ".delete", function () {
        var blogid = $(this).data("id");
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                $.ajax({
                    type: "post",
                    url: "/blog/delete",
                    data: {
                        _token: $('meta[name="csrf-token"]').attr("content"),
                        id: blogid,
                    },
                    success: function (res) {
                        if (res.status == true) {
                            Swal.fire(
                                "Deleted!",
                                "Your blog has been deleted.",
                                "success"
                            ).then(function () {
                                window.location.href = "/blog/list";
                            });
                        }
                    },
                });
            }
        });
    });

    $(document).on("click", ".mark_read", function () {
        // alert($(this).data('id'))
        $.ajax({
            type: "post",
            url: "/mark_read/mark_plan_read",
            data: {
                _token: $('meta[name="csrf-token"]').attr("content"),
                noti_id: $(this).data('id')
            },
            success: function (res) {
                if (res.status == true) {
                    
                    // refreshNotifications();
                    refreshAllNotifications() ;
                }
            },
        });
    });

    function refreshNotifications() {
        $.get('/user/notifications/refresh', function (data) {
            
            const notifications = data.notifications;

            $('.append_noti').html('');
            $('.append_badge_count').html('');
            if (notifications.length > 0) {
                
                notifications.forEach(function (result) {

                    // notification.forEach((result) => {
                        const createdAt = new Date(result.created_at);

                        // Define a function to format the date as desired
                        function formatDate(date) {
                        const options = { year: 'numeric', month: 'short', day: 'numeric'};
                        return date.toLocaleDateString('en-US', options);
                        }

                        function formatTime(date) {
                            const options = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
                            return date.toLocaleTimeString('en-US', options);
                        }

                        const formattedDate = formatDate(createdAt);
                        const formattedTime = formatTime(createdAt);

                            let html = `<li class="list-group-item">
                                            <div class="text-reset notification-item">
                                                <div class="media">
                                                    <i class="fas fa-info-circle fa-xl mt-3 mr-3 rounded-circle avatar-xs"></i>
                                                    <div class="media-body">
                                                        <a href="` + result.data.url + `">
                                                            <p class="mt-0 mb-1 detail ${result.read_at === null ? 'font-weight-bold' : ''}">
                                                            ${result.data.details}
                                                            </p>
                                                        </a>
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <div class="font-size-12 text-muted">
                                                                    <p class="mb-0"><i class=" mdi mdi-calendar-blank"></i>${formattedDate}</p>
                                                                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>${formattedTime}</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="font-size-12 text-muted">
                                                                    <p class="mb-1 mark_read" data-id="${result.id}">Mark as read</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>`;

                        $('.append_badge_count').html(notifications.length);
                        $('.append_noti').append(html);
                })

                }else{
                    let html = `<div class="text-reset notification-item">
                                    <div class="media" style="text-align:center">No Notification</div>
                                </div>`;
                    $('.append_noti').append(html);
                    $('.append_badge_count').html('');
                }
            });
    }

    if ($(".append_noti").length > 0) {
        $.ajax({
            type: 'get',
            url: '/user/notifications/refresh',
            dataType: 'json',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'), 
            },
            success: function (results) {
                //  console.log(results); 
                if (results) {
                    $('.append_noti').html('');
                    $('.append_badge_count').html('');

                        if (results.notifications.length > 0) {

                            results.notifications.forEach((result) => {
                                const createdAt = new Date(result.created_at);
        
                                // Define a function to format the date as desired
                                function formatDate(date) {
                                const options = { year: 'numeric', month: 'short', day: 'numeric'};
                                return date.toLocaleDateString('en-US', options);
                                }
        
                                function formatTime(date) {
                                    const options = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
                                    return date.toLocaleTimeString('en-US', options);
                                }

                                const formattedDate = formatDate(createdAt);
                                const formattedTime = formatTime(createdAt);
        
                            let html = `<div class="text-reset notification-item">
                                            <div class="media">
                                                <i class="fas fa-info-circle fa-xl mt-3 mr-3 rounded-circle avatar-xs"></i>
                                                <div class="media-body">
                                                    <a href="` + result.data.url + `">
                                                        <p class="mt-0 mb-1 detail ${result.read_at === null ? 'font-weight-bold' : ''}">
                                                        ${result.data.details}
                                                        </p>
                                                    </a>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="font-size-12 text-muted">
                                                                <p class="mb-0"><i class=" mdi mdi-calendar-blank"></i>${formattedDate}</p>
                                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i>${formattedTime}</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="font-size-12 text-muted">
                                                                <p class="mb-1 mark_read" data-id="${result.id}">Mark as read</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>`;
                                
                                $('.append_badge_count').html(results.notifications.length);
                                $('.append_noti').append(html);
                            })
                        }else{
                            let html = `<div class="text-reset notification-item">
                                            <div class="media" style="text-align:center">No Notification</div>
                                        </div>`;
                            $('.append_badge_count').html('');
                            $('.append_noti').append(html);
                        }
                    }
                }
            })
    }

    $(document).on("click", ".mark_as_read", function () {
        // alert($(this).data('id'))
        $.ajax({
            type: "post",
            url: "/mark_read/mark_plan_read",
            data: {
                _token: $('meta[name="csrf-token"]').attr("content"),
                noti_id: $(this).data('id')
            },
            success: function (res) {
                if (res.status == true) {
                    
                    refreshAllNotifications();
                }
            },
        });
    });

    function refreshAllNotifications() {
        $.get('/user/notifications/all', function (data) {

            const notifications = data.notifications;

            // Example:
            $('.append_all_noti').html('');

                if (notifications.length > 0) {
                    
                    notifications.forEach(function (result) {
                        const createdAt = new Date(result.created_at);

                        // Define a function to format the date as desired
                        function formatDate(date) {
                        const options = { year: 'numeric', month: 'short', day: 'numeric'};
                        return date.toLocaleDateString('en-US', options);
                        }

                        function formatTime(date) {
                            const options = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
                            return date.toLocaleTimeString('en-US', options);
                        }

                        const formattedDate = formatDate(createdAt);
                        const formattedTime = formatTime(createdAt);

                        let html = `<li class="list-group-item">
                                        <div class="text-reset notification-item">
                                            <div class="media">
                                                <i class="fas fa-info-circle fa-xl mt-3 mr-3 rounded-circle avatar-xs"></i>
                                                <div class="media-body">
                                                    <a href="` + result.data.url + `">
                                                        <p class="mt-0 mb-1 detail-${result.id} ${result.read_at === null ? 'font-weight-bold' : ''}">
                                                        ${result.data.details}
                                                        </p>
                                                    </a>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="font-size-12 text-muted">
                                                                <p class="mb-0"><i class=" mdi mdi-calendar-blank"></i>${formattedDate}</p>
                                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i>${formattedTime}</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="font-size-12 text-muted">
                                                                <p class="mb-1 mark_as_read" data-id="${result.id}">Mark as read</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>`;

                        $('.append_all_noti').append(html);
                        
                    })
                        refreshNotifications();
                }
                else{
                    let html = `<div class="text-reset notification-item">
                                    <div class="media" style="text-align:center">No Notification</div>
                                </div>`;
                    $('.append_all_noti').append(html);
                    // $('.append_badge_count').html(unread_noti.length);

                }
            });
    }

    if ($(".append_all_noti").length > 0) {
        $.ajax({
            type: 'get',
            url: '/user/notifications/all',
            dataType: 'json',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'), 
            },
            success: function (results) {
                if (results.status == true) {
                    $('.append_all_noti').html('');
                    // $('.append_badge_count').html('');

                        if (results.notifications.length > 0) {

                            results.notifications.forEach((result) => {
                                const createdAt = new Date(result.created_at);
        
                                // Define a function to format the date as desired
                                function formatDate(date) {
                                const options = { year: 'numeric', month: 'short', day: 'numeric'};
                                return date.toLocaleDateString('en-US', options);
                                }
        
                                function formatTime(date) {
                                    const options = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
                                    return date.toLocaleTimeString('en-US', options);
                                }

                                const formattedDate = formatDate(createdAt);
                                const formattedTime = formatTime(createdAt);
        
                            let html = `<li class="list-group-item">
                                            <div class="text-reset notification-item">
                                                <div class="media">
                                                    <i class="fas fa-info-circle fa-xl mt-3 mr-3 rounded-circle avatar-xs"></i>
                                                    <div class="media-body">
                                                        <a href="` + result.data.url + `">
                                                            <p class="mt-0 mb-1 detail-${result.id} ${result.read_at === null ? 'font-weight-bold' : ''}">
                                                            ${result.data.details}
                                                            </p>
                                                        </a>
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <div class="font-size-12 text-muted">
                                                                    <p class="mb-0"><i class=" mdi mdi-calendar-blank"></i>${formattedDate}</p>
                                                                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>${formattedTime}</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="font-size-12 text-muted">
                                                                    <p class="mb-1 mark_as_read" data-id="${result.id}">Mark as read</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>`;
                                
                                // $('.append_badge_count').html(results.notifications.length);
                                $('.append_all_noti').append(html);
                            })
                        }else{
                            let html = `<div class="text-reset notification-item">
                                            <div class="media" style="text-align:center">No Notification</div>
                                        </div>`;
                            // $('.append_badge_count').html('');
                            $('.append_all_noti').append(html);
                        }
                    }
                }
            })
    }

    if ($("#faq_category").length > 0) {
        $.ajax({
            type: "post",
            url: "/faq/ajaxgetCategories",
            data: {
                _token: $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (result) {
                if (result.status == true) {
                    if (!isEmpty(result.categories)) {
                        var addoption = "Select a category";
                    } else {
                        var addoption = "No category available";
                    }

                    $("#faq_category").html(
                        `<option value="0">` + addoption + `</option>`
                    );

                    result.categories.forEach((category) => {
                        $("#faq_category").append(
                            `<option value="` +
                                category.id +
                                `">` +
                                category.category_name +
                                `</option>`
                        );
                    });
                }
            },
        });
    }

    var faq_counter = 1;
    $(document).on("click", "#new_faq", function () {
        $.ajax({
            type: "post",
            url: "/faq/ajaxgetCategories",
            data: {
                _token: $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (result) {
                if (result.status == true) {
                    if (!isEmpty(result.categories)) {
                        var addoption = "Select a category";
                    } else {
                        var addoption = "No category available";
                    }

                    let html =
                        `<div class="col-12 faq_area counter_` +
                        faq_counter +
                        `"  data-counter="` +
                        faq_counter +
                        `">
                                    <div class="card">
                                        <div class="card-header text-primary d-flex justify-content-between">
                                            <span>Add Faq</span>
                                            <button class="btn btn-danger minusFaq" data-counter="` +
                        faq_counter +
                        `" type="button"><i class="fas fa-trash-alt"></i></button>
                                        </div>
                            
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="faq_category">Category</label>
                                                    <select id="faq_category` +
                        faq_counter +
                        `" name="faq_category[]" type="text" class="form-control">
                                                        <option value="">` +
                        addoption +
                        `</option>`;

                    result.categories.forEach((category) => {
                        html +=
                            `<option value="` +
                            category.id +
                            `">` +
                            category.category_name +
                            `</option>`;
                    });

                    html += `</select>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="quest">Questions <span class="text-danger">*</span></label>
                                                        <input id="quest" name="quest[]" type="text" class="form-control" placeholder="Enter the questions">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="answer">Answer <span class="text-danger">*</span></label>
                                                        <textarea id="answer" name="answer[]" class="form-control" placeholder="Enter the answer"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>`;

                    $(".faq_append_here").append(html);
                    faq_counter++;
                }
            },
        });
    });

    $(document).on("click", ".minusFaq", function () {
        $(".counter_" + $(this).data("counter")).remove();
    });

    var cate_counter = 1;
    $(document).on("click", "#addcate", function () {
        let html =
            `<div class="cate_content counter_` +
            cate_counter +
            `"  data-counter="` +
            cate_counter +
            `">
                        <div class="card">
                            <div class="card-header text-primary d-flex justify-content-between">
                                <span>Add Category</span>
                                <button class="btn btn-danger minusCate" data-counter="` +
            cate_counter +
            `" type="button"><i class="fas fa-trash-alt"></i></button>
                            </div>

                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="category">Category<span class="text-danger">*</span></label>
                                            <textarea id="category" name="category[]" type="text" class="form-control category" placeholder="Enter new category" style="height: auto"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>`;

        $(".add_category_append_here").append(html);
        cate_counter++;
    });

    $(document).on("click", ".minusCate", function () {
        $(".counter_" + $(this).data("counter")).remove();
    });

    $(document).on('click','.all_noti',function(){
        $.ajax({
            type: 'get',
            url: '/user/notifications/all',
            dataType: 'json',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'), 
            },
            success: function (results) {
                //  console.log(results); 
                if (results) {
                    $('.append_all_noti').html('');

                        if (results.notifications.length > 0) {

                            results.notifications.forEach((result) => {
                                const createdAt = new Date(result.created_at);
        
                                // Define a function to format the date as desired
                                function formatDate(date) {
                                const options = { year: 'numeric', month: 'short', day: 'numeric'};
                                return date.toLocaleDateString('en-US', options);
                                }
        
                                function formatTime(date) {
                                    const options = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
                                    return date.toLocaleTimeString('en-US', options);
                                }

                                const formattedDate = formatDate(createdAt);
                                const formattedTime = formatTime(createdAt);
        
                            let html = `<div class="text-reset notification-item">
                                            <div class="media">
                                                <i class="fas fa-info-circle fa-xl mt-3 mr-3 rounded-circle avatar-xs"></i>
                                                <div class="media-body">
                                                    <a href="` + result.data.url + `">
                                                        <p class="mt-0 mb-1 detail ${result.read_at === null ? 'font-weight-bold' : ''}">
                                                        ${result.data.details}
                                                        </p>
                                                    </a>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="font-size-12 text-muted">
                                                                <p class="mb-0"><i class=" mdi mdi-calendar-blank"></i>${formattedDate}</p>
                                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i>${formattedTime}</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="font-size-12 text-muted">
                                                                <p class="mb-1 mark_read" data-id="${result.id}">Mark as read</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>`;
                                
                                $('.append_all_noti').append(html);
                            })
                        }else{
                            let html = `<div class="text-reset notification-item">
                                            <div class="media" style="text-align:center">No Notification</div>
                                        </div>`;
                            $('.append_all_noti').append(html);
                        }
                    }
                }
            })
    })

    $(document).on('click','.read_noti',function(){
        $.ajax({
            type: 'get',
            url: '/user/notifications/read_noti',
            dataType: 'json',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'), 
            },
            success: function (results) {
                //  console.log(results); 
                if (results) {
                    $('.append_all_noti').html('');

                        if (results.notifications.length > 0) {

                            results.notifications.forEach((result) => {
                                const createdAt = new Date(result.created_at);
        
                                // Define a function to format the date as desired
                                function formatDate(date) {
                                const options = { year: 'numeric', month: 'short', day: 'numeric'};
                                return date.toLocaleDateString('en-US', options);
                                }
        
                                function formatTime(date) {
                                    const options = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
                                    return date.toLocaleTimeString('en-US', options);
                                }

                                const formattedDate = formatDate(createdAt);
                                const formattedTime = formatTime(createdAt);
        
                            let html = `<div class="text-reset notification-item">
                                            <div class="media">
                                                <i class="fas fa-info-circle fa-xl mt-3 mr-3 rounded-circle avatar-xs"></i>
                                                <div class="media-body">
                                                    <a href="` + result.data.url + `">
                                                        <p class="mt-0 mb-1 detail ${result.read_at === null ? 'font-weight-bold' : ''}">
                                                        ${result.data.details}
                                                        </p>
                                                    </a>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="font-size-12 text-muted">
                                                                <p class="mb-0"><i class=" mdi mdi-calendar-blank"></i>${formattedDate}</p>
                                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i>${formattedTime}</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="font-size-12 text-muted">
                                                                <p class="mb-1 mark_read" data-id="${result.id}">Mark as read</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>`;
                                
                                $('.append_all_noti').append(html);
                            })
                        }else{
                            let html = `<div class="text-reset notification-item">
                                            <div class="media" style="text-align:center">No Notification</div>
                                        </div>`;
                            $('.append_all_noti').append(html);
                        }
                    }
                }
            })
    })

    $(document).on('click','.unread_noti',function(){
        $.ajax({
            type: 'get',
            url: '/user/notifications/refresh',
            dataType: 'json',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'), 
            },
            success: function (results) {
                //  console.log(results); 
                if (results) {
                    $('.append_all_noti').html('');

                        if (results.notifications.length > 0) {

                            results.notifications.forEach((result) => {
                                const createdAt = new Date(result.created_at);
        
                                // Define a function to format the date as desired
                                function formatDate(date) {
                                const options = { year: 'numeric', month: 'short', day: 'numeric'};
                                return date.toLocaleDateString('en-US', options);
                                }
        
                                function formatTime(date) {
                                    const options = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
                                    return date.toLocaleTimeString('en-US', options);
                                }

                                const formattedDate = formatDate(createdAt);
                                const formattedTime = formatTime(createdAt);
        
                            let html = `<div class="text-reset notification-item">
                                            <div class="media">
                                                <i class="fas fa-info-circle fa-xl mt-3 mr-3 rounded-circle avatar-xs"></i>
                                                <div class="media-body">
                                                    <a href="` + result.data.url + `">
                                                        <p class="mt-0 mb-1 detail ${result.read_at === null ? 'font-weight-bold' : ''}">
                                                        ${result.data.details}
                                                        </p>
                                                    </a>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="font-size-12 text-muted">
                                                                <p class="mb-0"><i class=" mdi mdi-calendar-blank"></i>${formattedDate}</p>
                                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i>${formattedTime}</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="font-size-12 text-muted">
                                                                <p class="mb-1 mark_read" data-id="${result.id}">Mark as read</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>`;
                                
                                $('.append_all_noti').append(html);
                            })
                        }else{
                            let html = `<div class="text-reset notification-item">
                                            <div class="media" style="text-align:center">No Notification</div>
                                        </div>`;
                            $('.append_all_noti').append(html);
                        }
                    }
                }
            })
    })

});

function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}
